

import {computed, defineComponent, ref} from "vue";
import store from "@/store";

import Swal from "sweetalert2/dist/sweetalert2.js";
import PriceListAddNew from "@/views/apps/sys/mice/pricelists/PriceListsAddNew.vue";
import PriceListUpdate from "@/views/apps/sys/mice/pricelists/PriceListsUpdate.vue";
import priceListsCategory from "@/core/data/pricelistscategory";
import {Actions} from "@/store/enums/StoreEnums";
export default defineComponent({
  name: "hotel-list",
  components: {
    PriceListAddNew,
    PriceListUpdate
  },
  data: function () {
    return {
      imgUrl: '',
      searchName: '',
      sortField: '',
      sortType: '',
      filterField: ['Title'],
      filterFieldName: ['Liste İsmi'],
    }
  },
  props: {
    widgetClasses: String,
    tableData: Object,
  },
  computed: {

    filteredTableList: function () {
      if (this.tableData !== undefined){
        return this.tableData.filter(filteredList =>
            (filteredList.Title !== undefined && !(filteredList.Title).toLowerCase().indexOf(this.searchName.toLowerCase()))
        );
      }else{
        return [];
      }
    },

  },
  methods: {

  },
  setup() {
    let editListID ='';
    const myParams = computed(() => {
      return store.getters.generalParams;
    });
    const CategoryTitle = (item)=>{
      return priceListsCategory.TYPES.filter(items =>
              (items.Title !== undefined && items.Value == item)
      );
    };
    const updateActive = (ID, Active) => {
      const payload = {
        ID: ID,
        is_active: (Active == 'active') ? "passive" : "active",
      }
      store.dispatch(Actions.PRICE_LISTS_EDIT, payload)
    }
    const deleteItem = (ID) => {
      Swal.fire({
        title: 'Emin misiniz?',
        text: "Kaydi silmek işlemi geri alınamaz.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Kaydı Sil',
        cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d',
        cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
          const payload = {
            ID: ID,
          }
          store.dispatch(Actions.PRICE_LISTS_DELETE, payload)
              .then(() => {
                Swal.fire(
                    'Silindi!',
                    'Kayit silme işlemi tamamlandı.',
                    'success'
                )
              }).catch(() => {
            Swal.fire({
              text: store.getters.getPriceListsErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        }
      });

    }
    const orderSpec = (ID, Action) => {
      const payload = {
        ID: ID,
        Action: Action,
      }
      store.dispatch(Actions.PRICE_LISTS_ORDER, payload)
    }
    const setUpdate = (ID) => {
      const payload = {
        ID: ID,
      }
      store.dispatch(Actions.PRICE_LISTS_DETAILS, payload)
    }
    return {
      myParams,
      CategoryTitle,
      updateActive,
      deleteItem,
      orderSpec,
      editListID,
      setUpdate,
    }
  },
});
