
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import PriceLists from "@/components/mice/pricelists/List.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

//
export default defineComponent({
  name: "price-lists-settings",
  components: {
    PriceLists,
  },
  props:{
  },
  data: function () {
    return {
      myLoading: false,
    }
  },
  methods: {},
  watch: {

  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Fiyat Listeleri", ["MICE", "Fiyat Listeleri"]);
    });

    store.dispatch(Actions.PRICE_LISTS_LIST, {});
    const myList = computed(() => {
      return store.getters.priceListsList;
    });
    return {
      myList,
    };
  },
});
