
import {computed, defineComponent, ref} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import priceListsCategory from "@/core/data/pricelistscategory";


export default defineComponent({
  name: "new-price-list-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props:{
    listID: String,
    myID: String,
  },
  setup(props) {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const editPriceListModalRef = ref<null | HTMLElement>(null);


    const myDetails = computed(() => {
      return store.getters.priceListsDetails;
    });

    const validationSchema = Yup.object().shape({
      listTitle: Yup.string().required().label("Fiyat Listesi İsmi"),
      listCategory: Yup.string().required().label("Fiyat Listesi Kategory"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");


      const payload1 = {
        ID: myDetails.value.ID,
        Title: myDetails.value.Title,
        Category: myDetails.value.Category,
      }
      console.log(payload1);

      store.dispatch(Actions.PRICE_LISTS_EDIT, payload1)
          .then(() => {
            Swal.fire({
              text: "Tebrikler. Başarılı bir şekilde güncelleme işlemini tamamladınız.",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Geri Dön!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
              },
            }).then(function () {
              submitButtonRef.value.disabled = false;
              // Activate indicator
              submitButtonRef.value.setAttribute("data-kt-indicator", "off");
              hideModal(editPriceListModalRef.value);
              // Go to page after successfully login
              //router.push({name: "userlist"});
            });
          }).catch(() => {
        Swal.fire({
          text: store.getters.getPriceListsErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Tekrar dene!",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
        submitButtonRef.value.disabled = false;
        // Activate indicator
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      });
    };

    return {
      myDetails,
      priceListsCategory,
      validationSchema,
      submit,
      submitButtonRef,
      editPriceListModalRef,
    };
  },
});
