const priceListsCategory = {
  "TYPES" : [
    {
      Value : 'PRODUCTIONS',
      Title : 'PRODUKSİYON',
    },
    {
      Value : 'DECOR',
      Title : 'DEKOR',
    },
    {
      Value : 'PERSON',
      Title : 'HİZMET EKİBİ',
    },
    {
      Value : 'HOTELEXTRA',
      Title : 'OTEL EKSTRA',
    },
    {
      Value : 'EXTERNAL',
      Title : 'DIŞ MEKANLAR',
    },
    {
      Value : 'TRANSPORT',
      Title : 'TRANSFER',
    },
    {
      Value : 'OTHER',
      Title : 'DİĞER SERVİSLER',
    }],
};
export default priceListsCategory;
